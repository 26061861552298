.mainDSwrapper {
  border: 1px solid rgb(207, 207, 207);
  margin: 15px;
  border-radius: 5px;
  padding: 10px 20px;
  // width: 60%;
  width: 990px;
  min-height: 300px;
  margin-left: 30px;
  // background-color: rgb(247, 247, 247);
  background-color: #E6F4FF;
}

.mainDSwrapper input,
select {
  min-width: 320px;
  // height: 40px;
  padding: 8px;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
}


.mainDSwrapper textarea {
  padding: 8px;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  border: 1px solid #d3d3d3;
}

.mainDSwrapper select{
  // font-size: 12px;
  // height: 40px;
}

.pageHeading {
  // margin-top: 10px;
  margin-left: 30px;
}

.selectedImage {
  // border: 1.5px solid #b5d9ff;
  border: 1.5px solid #1890ff !important;
  border-radius: 8px !important;
  // box-shadow: 0px 0px 8px #666;
}

.individualRow {
  div {
    margin-right: 20px;
  }
  div #description {
    width: 100%;
    height: 75px;
  }
  #database,#clientId,
  #sfUsername {
    width: 100%;
  }
  #successPara {
    color: green;
  }

  .spinner {
    // border: 1px solid green;
    padding-top: 25px;
    padding-left: 10px;
  }

  display: flex;
  // justify-content: space-between;
  justify-content: left;
  flex: 1fr 1fr;
  // padding-right: 140px;
  margin: 10px 0px;
}

.inputRadioBtn {
  width: 80%;
  // padding-left: 10px;
  label {
    margin: 0px 10px;
  }
  .inputWrapper {
    display: flex;
    // justify-content: center;
    align-items: center;
  }
  input {
    min-width: 10px;
    // margin-right: 20px;
  }
}

.individualRowMetadata {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  margin: 15px 0px;
  gap: 10px;
}

.inputWrapper {
  width: 100%;
}

.imageWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  // border: 1px solid blue;
}

.databaseIconsImage {
  max-height: max-content;
  // max-width: max-content;
  width: 95px;
  border-radius: 3px;
  margin: 5px 12px 5px 0;
  display: flex;
  font-weight: 900;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // border: 1px solid red;
  padding: 8px;
  background-color: white;
  border: 1px solid rgb(234, 234, 234);
  cursor: pointer;

  img {
    // background-color: white;
    // border: 1px solid rgb(234, 234, 234);
    width: 60px;
    height: 50px;
    padding: 2px;
  }

  p {
    color: #333;
    font-size: 13px;
    font-family: "Poppins", sans-serif !important;
  }
}

.lastRow {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  select, input{
    // max-width: 50px !important; 
    min-width: 40px;
    max-width: 120px;
    margin: 2px 15px 0px 0;
    font-size: 13px;
    // border: 1px solid red;
    option{
      font-size: 20px;
      height: 20px;
    }
  }
}

.min-select { 
  // max-height: 40px;
  overflow-y: auto;
}

.job-btn {
  background-color: #1890ff;
  font-family: "Poppins", sans-serif ;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
  font-size: 16px;
  height: 40px !important;
  border: none;
  border-radius: 4px;

  &:hover {
    background-color: darken(#1890ff, 8%);
    color: #ffffff;
  }

  &:active {
    background-color: darken(#1890ff, 8%) !important;
    color: #ffffff !important;
    outline: none;
  }

  &:disabled {
    background-color: #ccc !important;
    color: #333 !important;
    outline: none;
  }
}

// job-btn btn nar-wid
.job-btn-clear {
  background-color: #d27454;
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
  font-size: 14px;

  &:hover {
    background-color: darken(#1890ff, 8%);
    color: #ffffff;
  }

  &:active {
    background-color: darken(#c26748, 8%) !important;
    color: #ffffff !important;
    outline: none;
  }
}

.nar-wid {
  width: auto !important;
  height: 1.9rem !important;
}

.btnWrapper {
  display: flex;
  width: 400px;
  margin-bottom: 20px;
  margin-left: 30px;
}

.passwordWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

//metadata crawl CSS

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  column-gap: 2rem; 
  // width: 1000px;
  width: 90%;
  // background-color: rgb(247, 247, 247);
  background-color: #E6F4FF;
  padding: 10px 30px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // background-color: #f9f9f9;
  // border: 1px solid #ccc;
}

.center {
  // padding-left: 45px;
}

.left-align {
  align-items: flex-start;
}

.side-by-side {
  flex-direction: row;
  align-items: center; 
  justify-content: left;
}

.side-by-side > .labelStyle {
  white-space: nowrap; /* Prevent line breaks */
  width: 100px;
}

.left-align input{
  min-width: none !important;
}
 
 

.grey-border {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  background-color: none;
}

.grey-border > div {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 5px;
  border-radius: 3px;
  background-color: #c5e1ff;
  border: 1px solid rgb(128, 213, 249);
  color: #333;
  height: 33px;
}


#filterCheckbox {
  transform: scale(0.4);
  // min-width: none !important;
}

.grid-item button {
  height: 36px !important;
  // width: 20px;
  // align-self: center;
  width: 45px !important;
  cursor: pointer;
}

.side-by-side-left {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  // margin: 0px;
  // padding-top: 22px;
  padding-bottom: 6px;
}


@media screen and (max-width: 831px) {
  .individualRow {
    padding-right: 0px;
    flex-wrap: wrap;
  }
}

.labelStyle {
  color: #555;
  font-size: 15px;
  padding-bottom: 5px;
  font-family: "Poppins", sans-serif !important;
}

.valign_bottom {
  vertical-align: bottom;
  align-content: flex-end;
}
.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}
