.progress-bar {
    width: 100%;
    height: 25px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
  }
  
  .progress-fill {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #fff;
    transition: width 0.5s ease;
    border-radius: 5px 0 0 5px;
  }
  
  .progress-text {
    position: absolute;
    right: 10px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
  }