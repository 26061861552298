.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.search_card:hover{
  /* background-color: #dfdfdf; */
  background-color: #E6F4FF;
}

/* react-paginate */
.navigationButtons {
  width: 100%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0.7rem auto;
  /* padding-top: 40px; */
}

.navigationButtons a {
  padding: 4px 8px;
  margin: 4px;
  border-radius: 5px;
  border: 1px solid #ddd;
  color: #666 !important;
  cursor: pointer;
  text-decoration: none;
  font-family: "Poppins", sans-serif !important;
}

.navigationButtons a:hover {
  color: #333333;
}

.navigationActive a {
  /* color: #ffffff !important; */
  color: #666666;
  /* background-color: #00243d; */
  background-color: #E6F4FF;
}

.navigationDisabled a {
  color: #c1c1c1;
  background-color: none;
  border-color: none !important;
}
.navigationDisabled a:hover {
  cursor: not-allowed;
  color: #c1c1c1;
}

/* Certified logo in dataset details page */
.certified-logo-size {
  width: 22px;
  padding-bottom: 3px;
  color: #108a13;
}

/* Details page selected tab  */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  background-color: #E6F4FF;
  color: #1890ff !important;
}

/* Classification icons css */
.dhc-header-line-lock-tag {
  /* border: 1px solid #4c9244; */
 /* background-color: #f3f3f3;
  border-radius: 18px; */
  padding: 0 0.8rem 0.2rem;
  margin: 0;
}

.dhc-header-message-classi {
  padding: 0.2rem .5rem;
  margin: 0;
  /* border: 1px solid #000000; */
  background-color: #E6E6E6;
  /* background-color: #E6F4FF; */
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  font-size: 12px;
  color: #000000;
  width: fit-content;
  display: inline-block;
}

.sensitivity-confidential{
  /* background-color:#dc3e3e !important;
  color: #fff !important; */
  color: #d6812b !important;
  }
  .sensitivity-internal{
    /* background-color: #d6812d !important;
    color: #fff !important; */
    color: #1890ff !important;
  }
  .sensitivity-public{
    /* background-color:#108a13 !important;
    color: #fff !important; */
    color: #108a13 !important;
  }

/* Metedata Score classNames */
.mtScore-gold{
  color: #FFD700;
  height: 1.4em !important;
  width: 1.4em !important;
  padding-top: 0.2rem;
  margin: 0;
}

.mtScore-silver{
  color: #C0C0C0;
  height: 1.4em !important;
  width: 1.4em !important;
  padding-top: 0.2rem;
  margin: 0;
}

.mtScore-bronze{
  color: #CD7F32;
  height: 1.4em !important;
  width: 1.4em !important;
  padding-top: 0.2rem;
  margin: 0;
}

.mtScore-null{
  display: none;
  height: 1.4em !important;
  width: 1.4em !important;
  padding-top: 0.2rem;
  margin: 0;
}

/* Rank metadata Insights classNames */

.mtRank-gold{
 background-color: #FFD700;
}

.mtRank-silver{
 background-color: #C0C0C0;
}

.mtRank-bronze{
 background-color: #CD7F32;
}

/* User Insights dummy pie design for total monthly user */
.pie {
  margin: 4rem auto;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: conic-gradient(
          #0c4a6e 0% 20%,
          #E6F4FF 20% 40%,
          #87CEFA 40% 65%,
          #1890ff 60% 80%,
          #0284c7 80%
  );
}

.pie.hollow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pie.hollow::after {
  content: '';
  position: absolute;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #FFFFFF;
}

.pie.hollow span {
  font-size: 24pt;
  z-index: 2;
  /* font-weight: bold; */
  font-family: "Poppins", sans-serif;
}

/* MUI Table pagination p tag margin bottom issue */
.MuiTablePagination-root p {
  margin-bottom: 0 !important;
}

/* Scroll bar css */
::-webkit-scrollbar-corner { background: rgba(0,0,0,0.5); }
 
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background: #f6f6f6;
}

*::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 20px;
  border: 3px solid #f6f6f6;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
