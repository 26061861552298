.myConPageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myConPageHeader h4 {
  margin-bottom: 0;
  font-weight: 800;
}

.myConPageContent {
  margin-top: 14px;
  display: grid;
  /* grid-template-columns: repeat(5, 1fr);  */
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 14px;
}

.MyConsingleCard {
  border: 1px solid #ddd;
  background-color: white;
  /* max-width: 250px; */
  /* width: 17rem; */
  /* width: auto; */
  height: 20rem;
  border-radius: 5px;
  padding: 1rem 1rem;
  margin: 0px 5px;
  /* margin-right: 22px; */
  margin-bottom: 16px;
  /* cursor: pointer; */
  transition: 0.3s;
  display: flex;
  flex-direction: column;
}

.MyConsingleCard div:last-of-type {
  margin-top: auto;
}

.MyConsingleCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  /* transform: scale(1.025); */
}

.imageText {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* margin-right: 40px; */
  /* max-width: 150px; */
}

.techLogo {
  /* margin-right: 10px; */
  width: 1.8rem;
  height: 1.8rem;
}

.techName {
  font-size: 12px;
  font-weight: 600;
  color: #007fff;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.myConName {
  display: inline-block;
  font-size: 24px;
  color: #474747;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
  width: 210px; 
}

.myConTimeDetails {
  font-size: 12px;
  font-style: italic;
  color: #888;
}

.myConActionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  width: 100%;
  color: #000000;
}

.myConActionBox {
  background-color: #eee;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* width: auto; */
}

.myConCrawlTrue {
  background-color: #89cff0;
  border-radius: 50%;
  /* width: fit-content;
  height: fit-content; */
  color: #333;
  font-size: 0.8rem;
  /* padding: 0.1rem 0.4rem; */
  height: 1.2rem;
  width: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myConCrawlFalse {
  background-color: red;
  border-radius: 50%;
  /* width: fit-content;
  height: fit-content; */
  color: #ffffff;
  font-size: 0.6rem;
  /* padding: 0.1rem 0.4rem; */
  height: 1.2rem;
  width: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myConCrawlNull {
  background-color: gray;
  border-radius: 50%;
  /* width: fit-content;
  height: fit-content; */
  color: #ffffff;
  font-size: 12px;
  padding: 0.1rem 0.4rem;
  height: 20px;
  width: 20px;
}


.round-blinker {
  position: relative;  
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: grey; /* Adjust based on status */
  display: inline-block;
  margin-right: 25px;
  cursor: pointer; /* Indicate it's hoverable */
  top: 7px;
}


.tooltip-text {
  visibility: hidden; /* Start hidden */
  width: 160px;  
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  opacity: 0; /* Start fully transparent */
  transition: visibility 0s, opacity 0.5s ease; /* Smooth transition for opacity */
}
 

.round-blinker:hover .tooltip-text {
  visibility: visible;
  opacity: 1; /* Fully visible on hover */
}

.tooltip-text {
  transform: translateX(-50%); /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s ease;
}


.blinker-base {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 25px;
  position: relative;
  top: 8px;
}

.not-started {
  background-color: grey;
}

.running {
  background-color: #39FF14; /* Neon Green */
  animation: blinker 1.5s infinite;
}

.completed {
  background-color: #006400; /* Bottle Green */
}

.no-match {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
  margin-top: 7%;
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}

