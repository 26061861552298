.BUBGStepperContainer {
  border: 1px solid #dfdfdf;
  border-bottom: none;
  background-color: #ffffff;
  padding: 1rem;
  margin-top: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.BUBGContentContainer {
  border: 1px solid #dfdfdf;
  background-color: #f4f5f7;
  padding: 1rem;
}

.BUBGStepper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.6rem;
  color: #000000;
  cursor: pointer;
}

.BUBGStepperNum{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #d3d3d3;
    border: 1px solid #d3d3d3;
    color: #005A9C;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BUBGStepperNum.Active{
    background-color: #19b35b;
    border: 1px solid #19b35b;
    color: #ffffff;
}

.p30{
  padding: 0 30px 30px;
}

.select__multiple {
  width: 400px;
  /* color: #d3d3d3; */
  color: #000000;
}

.biz-dom-wrap {
  /* // box-shadow: 0 4px 12px -12px gray; */
  padding: 10px 7px 9px 8px;
  /* // box-shadow: 4px 4px 4px -4px #eee; */
  /* // padding: 25px; */
  /* // background: #fff; */
  /* // border-radius: 10px; */
  margin-bottom: 15px;
  /* // border-bottom: 1px solid #ddd; */
}

.sub__header__bulk {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  color: #000000;
}

.spin__outlined {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
  font-size: 40px;
}

.margin__sub__bulk {
  margin-top: 2rem;
}

.input__file {
  /* font-family: "Poppins", sans-serif !important; */
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  width: 28rem;
}

.input__file::file-selector-button {
  color: #000000;
  background-color: #b5e0f5;
  border: 1px solid #b5e0f5;
  border-radius: 3px;
  cursor: pointer;
  height:2rem;
}

.input__file::-webkit-file-upload-button{
  height:2rem;
}

.bulk__btn {
  margin-left: 4rem;
  float: right;
  font-family: "Poppins", sans-serif !important;
  color: #ffffff !important;
  margin-top: -0.4rem;
}

.enabled_btn {
  background-color: #1976d2 !important;
  border-color: #1976d2;
  font-family: "Poppins", sans-serif !important;
  color: #ffffff;
}

.enabled_btn :hover {
  border-color: #1976d2;
  color: #ffffff !important;
}

.disabled_btn {
  background-color: gray !important;
  border-color: gray !important;
}

.py-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}

.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}