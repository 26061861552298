.search-filters-wrapper {
  margin: 1.4rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(210, 210, 210);
  /* height: auto; */
  /* border-bottom: 1px solid #d8d8d8; */
  & .filters-dropdown {
    display: flex;
    gap: 1rem;
    width: 85%;
    height: auto;
    flex-wrap: wrap;
  }
}
.business-unit-capsule-search-page {
  width: fit-content;
  padding: 2px 4px;
  background-color: rgb(179, 230, 198, 0.6);
  border-radius: 6px;
  border: 2px solid rgb(0, 144, 53);
  & p {
    font-size: 12px;
    margin: 0;
  }
}
.domain-capsule-search-page {
  width: fit-content;
  padding: 2px 4px;
  background-color: rgb(255, 230, 207, 0.6);
  border-radius: 6px;
  border: 2px solid rgb(255, 161, 73);
  & p {
    font-size: 12px;
    margin: 0;
  }
}
.sub-domain-capsule-search-page {
  width: fit-content;
  padding: 2px 4px;
  background-color: rgb(172, 200, 255, 0.6);
  border-radius: 6px;
  border: 2px solid rgb(32, 107, 255);
  & p {
    font-size: 12px;
    margin: 0;
  }
}
.product-capsule-search-page {
  width: fit-content;
  padding: 2px 4px;
  background-color: rgba(217, 217, 255, 0.6);
  border-radius: 6px;
  border: 2px solid #5252ff;
  & p {
    font-size: 12px;
    margin: 0;
  }
}

.show-more-owner {
  font-size: 14px;
  color: #1976d2;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.fqn-search-card-envelop {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 16px;
  border: 2px solid rgb(199, 199, 199);
  width: fit-content;

  & p {
    font-size: 14px;
    margin: 0;
    text-align: left;
    padding-right: 4px;
    color: #000;
  }
}

.database-image {
  width: 28px;
  height: 28px;
}

.second-line-meta-info-search-card {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */

  & .card-data-info {
    display: flex;
    justify-content: center;
    align-items: center;

    & p {
      margin: 0;
      font-size: 14px;
      color: rgb(150, 150, 150);
      margin-inline: 4px;
    }
  }

  & .owner-heading {
    & p {
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }
  }
}

#button-tooltipOwner > .tooltip-inner {
  background-color: #ffff;
  color: #000000;
  border: 1px solid #ffffff;
  padding: 1rem;
}

#tooltip-top > .tooltip-arrow {
  border-color: #ffffff !important;
}
