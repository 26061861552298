.GlossaryPageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.8rem;
}

.GlossaryHeader h3 {
  margin-bottom: 0;
  font-weight: 800;
}

.GlossaryContainer {
  /* max-width: max-content; */
  border: 1px solid #d3d3d3;
  padding: 0;
  display: flex;
  min-height: 100px;
  /* align-items: baseline; */
  /* justify-content: space-between; */
  /* gap: 18px; */
}

.GlossaryContainerTop {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 1rem;
  padding: 0;
}

.GlossaryContainerTop p {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  padding: 1rem;
  cursor: pointer;
}

.GlossaryContainerTop p:hover {
  background-color: #89cff0;
  border-radius: 8px 8px 0 0;
}

.GlossaryContainerTopActivep {
  background-color: #a0daff;
  border-bottom: 2px solid #04243a;
  border-radius: 8px 8px 0 0;
}

.dataFetchLength{
  /* padding: 2px; */
  font-size: 0.64rem;
  background-color: #d3d3d3;
  color: #000000;
  border-radius: 50%;
  height: 1.4rem;
  width: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p30{
  padding: 0 40px 30px;
}