.expand_collapse_button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #f6f6f6;
  cursor: pointer;
}

.dataset-node-static--style {
  background-color: #ffffff;
  border: 1px solid rgb(160, 160, 160);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 6px solid #001529;
  height: fit-content;

  & .dataset-node-top {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 60px;
    padding: 8px;
  }

  & .dataset-node-top-bottom {
    width: 280px;
    overflow: hidden;
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & input {
      width: 100%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    & .column-list {
      list-style: none;
      padding: 0;
      max-height: 240px;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;

      & .column-list-item {
        padding: 8px;
        background-color: #e6f4ff;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 10px;
        width: 240px;
        cursor: pointer;
      }
    }
    & .column-list::-webkit-scrollbar {
      width: 12px; /* Width of the scrollbar */
    }
    & .column-list::-webkit-scrollbar-thumb {
      background-color: #1b9cf7; /* Color of the scrollbar thumb */
      border-radius: 10px; /* Round edges of the scrollbar thumb */
      border: 3px solid #f1f1f1; /* Space around the scrollbar thumb */
    }
    & .column-list::-webkit-scrollbar-track {
      background: #f1f1f1; /* Color of the scrollbar track */
      border-radius: 10px; /* Round edges of the scrollbar track */
    }
  }
}

.dataset-node-content {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 210px;
  line-height: 14px;
  align-items: flex-start;
  justify-content: flex-start;
}
