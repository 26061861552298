/* Slide Panel */
.slide-panel {
  position: absolute; /* Positions the panel relative to the parent */
  top: 0;
  right: 0;
  height: 100%;
  width: 33.33%; /* Equivalent to w-1/3 */
  background-color: white; /* Equivalent to bg-white */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Equivalent to shadow-lg */
  transform: translateX(100%); /* Default state: off-screen */
  transition: transform 0.3s ease-in-out; /* Equivalent to transition-transform duration-300 ease-in-out */
  padding: 1.5rem; /* Equivalent to p-6 */
  overflow-y: auto; /* Equivalent to overflow-y-auto */
}

.slide-panel.open {
  transform: translateX(0); /* Slide in */
}

.slide-panel.closed {
  transform: translateX(100%); /* Slide out */
}

/* Close Button */
.close-button {
  position: absolute;
  top: 0.5rem; /* Equivalent to top-4 */
  right: 0.5rem; /* Equivalent to right-4 */
  font-size: 1.5rem; /* Equivalent to text-2xl */
  color: inherit;
  cursor: pointer;
  transition: color 0.2s ease;
}

.close-button:hover {
  color: #f56565; /* Equivalent to hover:text-red-500 */
}

.panel-wrapper {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
/* Panel Title */
.panel-title {
  font-size: 0.8rem; /* Equivalent to text-xl */
  font-weight: bold; /* Equivalent to font-bold */
  margin-bottom: 1rem;
  width: 100%; /* Equivalent to mb-4 */
}
