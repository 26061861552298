.tags__container {
  margin-top: 4rem !important;
  h3 {
    font-family:"Poppins", sans-serif !important;
    font-size: 22px;
  }
}
.list__items {
  font-family: "Poppins", sans-serif !important;
  .badge {
    border-radius: 0.2rem !important;
    width: 40px;
    background-color: #058ec6 !important;
    font-family: "Poppins", sans-serif;
  }
}
.form__view {
  font-family: "Poppins", sans-serif !important;
}
.modal-footer {
  font-family: "Poppins", sans-serif !important;
}
.action__icon {
  display: inline-flex;
  gap: 16px;
  button {
    background: none !important;
    border: none !important;
    width: 24px;
    height: 24px;
  }
  img {
    width: 36px;
    height: 36px;
  }
}
.editicon {
  color: #263238;
}
.deleteicon {
  color: #b80012;
}
.classification {
  font-family:"Poppins", sans-serif !important;
  color: #ffffff;
}
.tags__btn {
  display: inline-flex;
  gap: 10px;
  float: right;
  margin-top: 0rem;
}

.pop__btn {
  font-size: 14px !important;
  height: 32px !important;
  padding: 4px 15px !important;
  border-radius: 6px !important;
}

.enabled_btn {
 // background-color: #058ec6 !important;
  // border-color: #058ec6;
  background-color: #1976d2;
  border-color: #1976d2;
  font-family: "Poppins", sans-serif !important;
  color: #ffffff;

  &:hover {
    // border-color: #058ec6;
    border-color: #1976d2;
    color: #ffffff !important;
  }
}

.disabled_btn {
  background-color: gray !important;
  border-color: gray !important;
}
.classification__view {
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1%);
  border-radius: 0.5rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
}

.list-group-item{
  cursor: pointer;
}

.list-group-item.active {
  z-index: 2;
  color: #333333 !important;
  // background-color: $info__color !important;
  // border-color: $info__color !important;
  // background-color: $white__gray__color !important;
  // border-color: $gray__color !important;
  background-color: #ebf6fb;
  border-color: #b8ced8;
}
.button_save_close {
  display: flex;
  gap: 0.9rem;
  float: right;
}
.class__grp {
  p {
    font-family: "Poppins", sans-serif !important;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
}
.classidelete {
  display: inline-flex;
  gap: 3rem;
  button {
    border-color: #058ec6;
    font-family: "Poppins", sans-serif;
  }
  .delete_btn {
    background-color: #058ec6 !important;
    border-color: #058ec6;
    font-family: "Poppins", sans-serif !important;
  }
}
.header__desc {
  margin-top: 10px;
  text-align: left;
  h2 {
    font-family: "Poppins", sans-serif !important;
    font-size: 24px;
  }
  p {
    font-family: "Poppins", sans-serif !important;
    font-size: 18px;
  }
}
.title__header {
  border-bottom: 1px solid #ced4da !important;
  h4 {
    font-size: 20px !important;
    color: gray;
    font-family: "Poppins", sans-serif !important;
  }
}
.del__msg {
  color: #333333;
  span {
    font-family: "Poppins", sans-serif !important;
    font-size: 20px;
  }
}
.steps__desc {
  color: #77c6e0;
  p {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
  }
  span {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
  }
}
.desc__title {
  font-family: "Poppins", sans-serif !important;
}
.note__desc {
  font-family: "Poppins", sans-serif !important;
}

.label__header__name__required {
  span {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
  }
}

.label__header__name__required::before {
  content: "*";
  color: #ff725e;
  margin-right: 4px;
  font-size: 14px;
}
.label__header__name {
  span {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
  }
}
.edit__classi {
  display: inline-flex;
  float: right;
  margin-top: -2rem;

  button {
    background: none !important;
    border: none !important;
    width: 32px;
    height: 32px;
  }
  img {
    width: 32px;
    height: 32px;
    margin-left: -0.1rem;
  }
}

.ant-table-content {
  color: #333333;
  font-family: "Poppins", sans-serif !important;
  font-size: 15px;
  .ant-table-thead {
    th {
      font-family: "Poppins", sans-serif !important;
    }
  }
}
