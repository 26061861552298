.TagsPageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-left: -15px;
}

.TagsHeader h3 {
  margin-bottom: 0;
  font-weight: 800;
}

.TagContainer {
  /* max-width: max-content; */
  border: 1px solid #d3d3d3;
  padding: 0;
  display: flex;
  min-height: 100px;
  /* align-items: baseline; */
  /* justify-content: space-between; */
  /* gap: 18px; */
}

.TagContainerTop {
  display: flex;
  align-items: center;
  padding: 0;
}

.TagContainerTop p {
  /* color: #000000; */
  font-size: 16px;
  /* font-weight: 800; */
  padding: 10px 30px;
  cursor: pointer;
  /* height: 50px; */
}

.TagContainerTop p:hover {
  background-color: #eee;
  border-radius: 8px 8px 0 0;
}

.TagContainerTopActivep {
  /* background-color: #a0daff; */
  background-color: #E6F4FF;
  color: #1890ff;
  border-bottom: 2px solid #1976d2;
  /* border-radius: 8px 8px 0 0; */
  border-radius: 4px 4px 0 0;
}

.TagContainerTopActivep:hover  {
  background-color: #a0daff !important;
}

.dataFetchLength{
  /* padding: 2px; */
  font-size: 0.64rem;
  background-color: #d3d3d3;
  color: #000000;
  border-radius: 50%;
  height: 1.4rem;
  width: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}