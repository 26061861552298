.manageViewsContainer {
  margin: 2rem;
}

.manageViewsContainer h3 {
  text-align: left;
}

.addNewButton {
  float: right;
  margin: 1.4rem 0.4rem !important;
  text-transform: none;
  background-color: #1890ff;
}

.actionIcons {
  cursor: pointer;
  height: 36px;
  width: 36px;
  padding: -8px;
  margin: -8px 16px -8px -8px;
  float: left;
}

.viewModalInputs {
  margin: 1.4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
