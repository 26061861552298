/* DatasetInfoCard.css */
.dataset-info-card {
  width: 100%;

  padding: 5px;
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  /* border: 1px solid #ccc; */
  position: relative;
  border-radius: 4px;
  background-color: #fff;

  & .dataset-card-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 6px;
  }
}

.dataset-title {
  margin-top: 1;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
}

.dataset-description {
  font-size: 14px;
  width: 100%;
  min-height: 60px;
  height: fit-content;
  text-align: left;
  color: #666;
  padding-bottom: 8px;
  margin-bottom: 0;
}

.dataset-details,
.dataset-metrics {
  margin-top: 10px;
  /* border: 1px solid #ccc; */
  width: 100%;
  font-size: 14px;
}

.detail-row-uris {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0px;

  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-start;
  margin-bottom: 6px;

  & .button-link {
    display: inline-block;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    color: black;
    background-color: transparent;
    border: 2px solid black;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }

  & .button-link:hover {
    background-color: #000;
    color: white;
  }
}

.detail-row,
.metric-row {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  gap: 8px;
  justify-content: flex-start;
  margin-bottom: 6px;
}

.detail-label,
.metric-label {
  font-weight: bold;
  color: #000;
}

.detail-value,
.metric-value {
  color: #666;
  font-size: 12px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}
