.card-container {
  display: flex;
  width: 80%;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 1rem;
  /* padding-bottom: 0; */
  gap: 20px;
  /* margin-left: 2rem; */
  /* margin: 0 auto; */
}

.category-card-outer{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.4rem;
  margin: 0 auto;
}

.LandingCategoryCard {
  /* padding: 1rem; */
  /* background: #e6e6fa; */
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
  min-width: 300px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  white-space: nowrap;
  font-size: large;
}

.LandingCategoryCard.selected {
  color: #1890ff;
  transform: scale(0.96);
  /* background: #b3b3d9; Highlight color for selected card */
  /* border: 2px solid #666; Optional border for more visibility */
  border: 2px solid #1890ff;
  border-bottom: 6px solid #1890ff;
  border-radius: 8px 8px 0 0;
}

.content {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 2rem;
  /* padding: 1rem; */
  background-color: #e6f4ff;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.87);
}

.content-body {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
  /* justify-content: start; */
  /* align-items: flex-start; */
  margin-top: 1rem;
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
  gap: 16px;
  /* height: 600px; */
  width: 100%;
  margin-inline: 2%;
  /* height: 11rem; */
  overflow: auto;
}

.content-card {
  width: 260px;
  height: 100px;
  /* border: 1px solid gray; */
  display: flex;

  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
